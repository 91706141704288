@import "./home/variables.scss";

.small-image {
  width: 90px;
  height: 90px;
  margin-bottom: 10px;
}

.medium-image {
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
}

.font-weight-bolder {
  font-weight: 900;
}

.removeBullets {
  list-style-type: none;
}

.modal-80w {
  min-width: 80%;
}

.modal.fade:not(.in).right .modal-dialog {
  -webkit-transform: translate3d(25%, 0, 0);
  transform: translate3d(25%, 0, 0);
}

.textButton {
  color: inherit;
  background: none;
  border: none;
}

.filled {
  .form-select {
    min-height: 44px;
    border: 1px solid transparent;
    background: var(--background-light);
    padding-left: 45px;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 10.127L12 18.127L20 10.127H4Z" fill="%238E8E93"/></svg>');
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 98%;
  }
  .form-select:focus {
    border-color: rgba(var(--primary-rgb), 1);
    background: initial;
  }
  .form-select:disabled,
  .form-select[readonly] {
    background: rgba(var(--separator-rgb), 0.5) !important;
    color: var(--muted);
  }
  .form-select:disabled > svg,
  .form-select[readonly] > svg {
    color: rgba(var(--alternate-rgb), 0.25);
  }
}

.form-check-input {
  cursor: pointer !important;
}
.form-check-label {
  cursor: pointer !important;
}

.school-box-welcome-width {
  max-width: 550px;

  h1 {
    font-size: 30px;
    font-weight: bolder;
  }

  p {
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
  }
}

.rdt_TableRow {
  cursor: pointer;
}

@media (max-width: 575px) {
  #registerForm {
    .row {
      .student-setup {
        justify-content: center !important;
        align-items: center !important;
        button {
          margin-top: 10px;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

#__next {
  .fullLayout {
    main {
      padding: 7% 30px;
      @media (max-width: 1199px) {
        padding: 9vw 15px !important;
      }
    }
  }
}

.offcanvas {
  .modal-header {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
  }
  // .modal-body {
  //   overflow-y: scroll !important;
  // }
}

.cancel_icon {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
}

@keyframes slideIn {
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}

.herzogLogo {
  img {
    height: auto;
    min-width: 55% !important;
    object-fit: cover;
    margin: 0px !important;
  }
}

.header {
  .animated {
    .header-dropdown {
      #toggle-menu {
        &:after {
          display: none !important;
        }
        &:hover {
          background: $btn-color;
          color: #fff !important;
          text-decoration: none;
        }
        &:focus {
          background: $btn-color;
          color: #fff !important;
          text-decoration: none;
        }
      }
      .dropdown-menu {
        background-color: #f3f3f3;
        border: none;
        border-radius: 7px;
        a {
          color: #555555;
          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
      .show {
        top: 35px !important;
        -webkit-animation-name: slideIn;
        animation-name: slideIn;
        animation-duration: 0.4s;
        animation-fill-mode: both;
      }
    }
  }
}

.error {
}

.school-details {
  .row {
    .leftBlock {
      border-radius: 23px;
      background-color: white;
      padding: 0;
      height: max-content;

      .school-image {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
      }
      .school-detail-wrapper {
        padding: 30px 10px;
        .row {
          padding: 0px;
          margin: 0px;
        }
        .school-detail {
          h5 {
            padding-left: 0px;
            padding-right: 0px;
          }
          .address {
            padding-left: 0px;
            padding-right: 0px;
            display: flex;
            flex-direction: row;
            svg {
              margin-top: 4px;
            }
            p {
              font-size: 14px;
            }
          }
        }
        .lead-details {
          display: flex;
          flex-direction: row;
          .lead-image {
            width: 20%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 0px;
            padding: 0px;
            @media (max-width: 767px) {
              width: 10%;
            }
            img {
              border-radius: 25px;
              width: 50px;
            }
          }
          .lead-name {
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media (max-width: 767px) {
              width: 90%;
            }
            h6 {
              margin: 0px;
              padding: 0px;
              font-size: 13px;
              font-weight: 600;
            }
            p {
              margin: 0px;
              padding: 0px;
              font-size: 11px;
            }
          }
        }
        .lead-button-wrapper {
          margin-top: 10px;
          button {
            margin-right: 10px;
          }
        }
      }
    }

    .rightBlock {
      padding: 0;
      padding-left: 30px;

      @media (max-width: 991px) {
        padding: 0px;
        margin-top: 30px;
      }

      .school-overview-content-wrapper {
        margin: 10px;
        padding: 30px;
        background-color: #fff;
        border-radius: 23px;
        flex-direction: column;

        .select-photo-wrapper {
          border-radius: 23px;
          border: 1px solid #555555;
          width: max-content;
          overflow: hidden;

          .school-image {
            border-right: 1px solid #555555;
            width: 100%;
            height: 100%;
          }

          .choose-photo {
            padding: 20px 20px;
          }
        }

        .school-name {
          border: 1px solid #555555;
          border-radius: 23px;
          overflow: hidden;

          .mb-3 {
            margin: 0 !important;
          }
        }

        .save-btn {
          width: max-content;
        }

        @media (max-width: 767px) {
          padding: 20px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #__next {
    #root {
      .container {
        .h-100 {
          padding-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  #__next {
    #root {
      .container {
        .h-100 {
          padding-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 625px) {
  #__next {
    .fullLayout {
      padding-bottom: 100px;
    }
  }
}

.loadingButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 7px;
  }
}

.manage-user-page {
  .contact-us-btn {
    margin-left: 10px !important;

    button {
      padding: 12px 25px !important;
      height: auto !important;
    }
  }
  .rdt_Table {
    background-color: transparent;
    .rdt_TableHead {
      background-color: transparent;
      .rdt_TableHeadRow {
        background-color: transparent;
        padding: 23px 0px;
        border-bottom-style: none;
        border-bottom-width: 0;
      }
    }
    .rdt_TableBody {
      background-color: transparent;
      .rdt_TableRow {
        border-radius: 27px;
        background-color: white;
        border-bottom-style: none;
        border-bottom-width: 0;
        margin-bottom: 17px;
        .text-block {
          padding: 0px 17px;
          display: table-cell;
          vertical-align: middle;
        }
        [data-column-id="1"] {
          padding: 0px;
          span {
            border-top-left-radius: 27px;
            border-bottom-left-radius: 27px;
          }
        }
        [data-role="Admin"] {
          background-color: var(--primary);
          color: white;
          padding: 7px 17px;
          margin-top: 25px;
          border-radius: 25px;
        }
        [data-role="Mentor"] {
          padding: 7px 17px;
          margin-top: 25px;
          border-radius: 25px;
          border: solid 1px black;
        }
        .actions {
          display: flex;
          flex-direction: column;
          button {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .rdt_Pagination {
    border-top-style: none;
    border-top-width: 0;
    justify-content: center;
    -webkit-box-align: center;
  }
}

.shadow {
  box-shadow: 0 1px 10px rgba(90, 89, 89, 0.1),
    0 -1px 10px rgba(90, 89, 89, 0.1) !important;
}

.link {
  cursor: pointer;
}

.card {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
  background: var(--foreground);
  border-radius: var(--border-radius-lg);
  border: initial;
  position: relative; overflow: hidden;
  &.studentCard {
    text-align: center;
    padding: 1.7rem 1.6rem;
    @media (max-width: 991px) {
      padding: 15px;
    }
  }
  &.tabularData {
    padding: 0;
    margin-bottom: .8rem;
  }
  &.applicationCard{
    padding: 1.65rem 1.55rem;
  }
}
.page-title-container {
  margin-bottom: 0 !important;
  padding-top: 0.6rem;

  h1 {
    font-weight: 300;
    font-size: 1.5556rem;
    line-height: 1.214em;
    color: #4e4e4e;
  }
}
.linkOver {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#__next {
  .container {
    max-width: 1427px;
    .container {
      max-width: 100%;
      padding: 0;
    }
  }
  #home-page {
    .container {
      max-width: 1280px;
    }
  }
}
.studentImg {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto 1.25rem;
}
.schoolImg {
	margin: 0 auto 1.25rem;
}
.dashboard-container {
  position: relative;
  display: flex;
  @media (max-width: 767px) {
    display: block;
  }
  .dashboard-sidebar {
    width: 24.3%;
    @media (max-width: 1199px) {
      width: 28%;
    }
    @media (max-width: 767px) {
      width: 100%; margin-bottom: 2rem;
    }
  }
  .dashboard-content {
    width: 50%;
    flex-grow: 1;
    padding-left: 1.7rem;
    @media (max-width: 767px) {
      width: 100%; padding-left: 0;
    }
  }
}
.studentName {
  font-weight: 400;
  font-size: 1.16667rem;
  line-height: 1.1em;
  color: #4e4e4e;
  text-transform: capitalize;
  @media (max-width: 991px) {
    font-size: 1rem;
  }
}
.studentStatus {
  border: 1px solid #3a4735;
  display: inline;
  text-transform: uppercase;
  border-radius: 1.45rem;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.6em;
  color: #3a4735;
  width: 130px;
  margin: 0.1rem auto 0;
  text-align: center;
}
.studentActions {
  padding-top: 0.55rem;
  display: flex;
  .btn {
    flex-grow: 1;
    margin: 0 2px;
    width: 50%;
    font-size: 0.7778rem;
    border-radius: 0.66667rem;
  }
}
.studentContacts {
  text-align: left;
  padding-top: 1.5rem;
  h6 {
    font-weight: 400;
    font-size: 11px;
    line-height: 1.181em;
    color: #afafaf;
    text-transform: uppercase;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-top: 0.35rem;
    li {
      position: relative;
      padding-left: 2.05em;
      font-weight: 400;
      font-size: 0.77778rem;
      line-height: 1.29em;
      color: #7c7c7c;
      padding-bottom: 0.35em;
      min-height: 23px;
      i {
        position: absolute;
        left: 0;
        width: 1.5em;
        font-size: 1.25em;
      }
      .teamLeaderImg{
        width: 22px; height:22px; border-radius:100%;
        position: absolute;
        left: 0; overflow: hidden;
        img {
          object-fit: cover;
          object-position: center center;
          width:100%;
          height:100%;
        }
      }
    }
  }
}
body {
  .nav-tabs {
    border-bottom: solid 1px #dddddd;
    padding-top: .9rem;
    li{
      margin-right: 1.7rem;
      .nav-link{
        font-family: var(--font-heading);
        font-weight: 400;
font-size: .8333rem;
line-height: 1.2em;
color: #AFAFAF;  border: none; padding: 0 0 0.3em !important;
&.active{
  background: none; color: var(--primary); border-bottom: solid 1px var(--primary);
}
      }
    }
  }
}
.tabData {
	padding: .9rem;
  @media (max-width: 991px) {
    padding: .9rem 0;
  }
}
.tabularData{
  font-size: .8333rem;
line-height: 1.2em;
color: #000000; 
font-family: var(--font-heading);
}
.applicationQs{
  position: relative;
}
.applicationQbx{
  position: relative;
  margin-bottom: .75rem;
  .applicationQ{
    font-weight: 400;
font-size: .7778rem;
line-height: 1.285em;
color: #7C7C7C; 
font-family: var(--font-body);
  }
   .applicationA {
    background: #F9F9F9;
    border-radius: 12px;
    padding: 0.7em 0.8rem;
    font-weight: 400;
    font-size: 0.7778rem;
    line-height: 1.285em;
    color: #4E4E4E;
    
  }
}
.selection-btns{
  .btn{
    font-size: .95rem;
    svg{
      display: inline-block; vertical-align: middle; margin-right: .2em;
    }
    span{
      display: inline-block; vertical-align: middle; 
    }
  }
}
.flex{
  &.justify-space-between{
    justify-content: space-between;
  }
  &.align-items-center{
    align-items: center;
  }
}
body{
  .rdt_Table{
    border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
    overflow: hidden;
    font-size: 0.8333rem; text-transform: none;
    .rdt_TableHead {
      font-family: var(--font-heading);
      font-weight: 700;
    }
    .rdt_TableBody{
      font-size: 0.8333rem;
    }
    .fvXJdi{
      min-height: 60px;
      font-size: 0.8333rem;
      img{
        width: 60px !important; height: 60px !important;
      }
    }
  }
  .rdt_Pagination{
    font-size: 0.8333rem;
    border-radius:0 0 var(--border-radius-md) var(--border-radius-md);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important
  }
}
.section-tell-us{
  position: relative;
  border-radius:var(--border-radius-lg); overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03);
  &:before{
    width: 74%; height:100%; content: ''; background-image:url(/img/illustration/bg_tell_us.jpg); position: absolute; right: 0;
    top: 0; background-position: center; background-repeat: no-repeat; background-size: cover;
  }
  &:after{
    width: 100%; height:100%; content: ''; 
    background: linear-gradient(91.45deg, #FFFFFF 34.26%, rgba(255, 255, 255, 0) 62.01%); position: absolute; left: 0; top: 0;
  }
  .col{
    position: relative; z-index: 9;
  }
  h5{
    font-style: normal;
font-weight: 300;
font-size: 1.333rem;
line-height: 1.208em;
color: #4E4E4E;
  }
  p{
    font-weight: 400;
font-size: .7778rem;
line-height: 1.285em;
color: #4E4E4E;
  }
}