/* Default Variables */
$header-font: "museo-sans",sans-serif;
$body-font:  "museo-sans",sans-serif;
$button-font:  "museo-sans",sans-serif;

/* Form and Button Styling */
$button-radius: 47px;
$form-radius: 47px;
$line-height-desktop: 54px;
$line-height-mobile: 40px;

$base-fontsize: 18px;
$base-lineheight: 1.45em;

$max-width: 1280px;
$body-background: #213452;

$transition: .25s;

/* Colors */
$default: #333;
$light-grey: #555;
$btn-color: $default;
$facebook-blue: #3b5998;
$twitter-blue: #00ACEE;
$highlight: #BB895B;
$blue: #354866;

html {
  scroll-behavior: smooth;
}

/* Custom Layout Overrides */
html, body {
  overflow-x: hidden;
  font-size:$base-fontsize;
  background:$body-background;
  font-family:$body-font;
}

section {
  padding: 2rem 0;
  font-size: $base-fontsize;
  position:relative;

  @media(min-width:768px){
    padding:3rem 0;
  }

  @media(min-width:992px){
    padding:5rem 0;
  }

  @media(min-width:1200px){
    padding:8rem 0;
  }

  .container {
    .row {
      .button {
        margin-top:1rem;
      }
      form {
        .button {
          margin-top: 0;
        }
      }
    }
  }
}

.container {
  width:100%;
  max-width:$max-width;
  margin:0 auto;
}

/* Font Styles */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  font: {
    family: $header-font;
    weight:bold;
  }
  margin:0;
  padding:0;
  line-height:1em;

  a {
    text-decoration:none;
    -webkit-transition: all $transition;
    -moz-transition: all $transition;
    -ms-transition: all $transition;
    -o-transition: all $transition;
    transition: all $transition;

    &:hover, &:focus {
      text-decoration:none;
    }
  }
}

p, li {
  font-size:1em;
  margin:.75em 0 0 0;
  padding:0;
  font: {
    family: $body-font;
    size: 1em;
  }

  @media(min-width:768px){
    font-size:1.15em;
  }

  @media(min-width:1200px){
    font-size:1.25em;
  }

}

/* Base Styles */

.button {
  background:$btn-color;
  color:#fff;
  font-weight:700;
  font-family:$button-font;
  height:$line-height-desktop;
  line-height:$line-height-desktop - 5px;
  padding:0 1.15rem;
  display: inline-block;
  -webkit-border-radius: $button-radius;
  -moz-border-radius:$button-radius;
  border-radius: $button-radius;
  font-size:1.15rem;
  -webkit-transition: all $transition;
  -moz-transition: all $transition;
  -ms-transition: all $transition;
  -o-transition: all $transition;
  transition: all $transition;
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  -ms-transform: scale(1,1);
  -o-transform: scale(1,1);
  transform: scale(1,1);
  border: 2px solid $btn-color;
  text-align: center;

  @media(max-width:767px){
    height: $line-height-mobile;
    line-height: $line-height-mobile - 5px;
  }

  &:hover, &:focus {
    text-decoration:none;
    outline: none;
    color:#fff;
    -webkit-transform: scale(1.045,1.045);
    -moz-transform: scale(1.045,1.045);
    -ms-transform: scale(1.045,1.045);
    -o-transform: scale(1.045,1.045);
    transform: scale(1.045,1.045);
  }

  &.buttonOutline {
    background:transparent;
    color:$btn-color;
  }

  &.inactive {
    opacity: .5;
    cursor: default;

    &:hover, &:focus {
      text-decoration:none;
      outline: none;
      color:#fff;
      -webkit-transform: scale(1,1);
      -moz-transform: scale(1,1);
      -ms-transform: scale(1,1);
      -o-transform: scale(1,1);
      transform: scale(1,1);
    }
  }

}

form {
  @extend .row;
  font-size:$base-fontsize;
  flex-wrap: wrap;

  .col{
    position:relative;
    $input-padding-top: .5rem;
    $input-padding-right: .5rem;
    $input-padding-bottom: .5rem;
    $input-padding-left: .5rem;
    padding:$input-padding-top $input-padding-right $input-padding-bottom $input-padding-left;

    label {
      position:absolute;
      font-size:1.25rem;
      line-height:$line-height-desktop;
      left:.85rem + $input-padding-left;
      pointer-events: none;
      -webkit-transition: all .25s ease-in-out;
      -moz-transition: all .25s ease-in-out;
      -ms-transition: all .25s ease-in-out;
      -o-transition: all .25s ease-in-out;
      transition: all .25s ease-in-out;
      font-weight:400;

      @media(max-width:767px){
        line-height: $line-height-mobile;
      }

      &.toggle-label {
        position:absolute;
        top:.75rem + $input-padding-top;
        left:1rem + $input-padding-left;
        line-height:.75rem;
        font-size:.75rem;
        font-weight:700;
        text-transform:uppercase;
        letter-spacing:.05rem;
      }
    }

    &.toggle-label {
      label {
        position:absolute;
        top:.75rem + $input-padding-top;
        left:.85rem + $input-padding-left;
        line-height:.75rem;
        font-size:.75rem;
        font-weight:700;
        letter-spacing:.05rem;

        @media(max-width:767px){
          top:.55rem + $input-padding-top;
          left:.75rem + $input-padding-left;
        }
      }
    }

    input, textarea, select {
      border:none;
      padding:0.7rem .75rem .25rem .7rem;
      font-size:1.25rem;
      color:$default;
      width:100%;
      height:$line-height-desktop;
      line-height:$line-height-desktop - 5px;
      -webkit-border-radius: $form-radius;
      -moz-border-radius: $form-radius;
      border-radius: $form-radius;
      -webkit-transition: all .35s;
      -moz-transition: all .35s;
      -ms-transition: all .35s;
      -o-transition: all .35s;
      transition: all .35s;
      border: 2px solid;

      //@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      //  line-height:2.2rem;
      //}

      @media(max-width:767px){
        height: $line-height-mobile;
        line-height: $line-height-mobile - 5px;
        font-size:1.1rem;
        padding:0.7rem .75rem .25rem .7rem;
      }

     

      &:focus {
        outline:none;
        border:2px solid $btn-color;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          opacity:1;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          opacity:1;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          opacity:1;
        }
        &:-moz-placeholder { /* Firefox 18- */
          opacity:1;
        }
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &:-ms-input-placeholder { /* IE 10+ */
          color: $light-grey;
          opacity: 1;
        }
      }

    }

    textarea {
      width:100%;
      max-width:100%;
      min-width:100%;
      overflow: hidden;
      height:200px;
      min-height:200px;
      line-height:1em;
      padding-top:25px;
    }

    button, .button {
      width:100%;
      margin-top:0;
      padding: 0;
      white-space: nowrap;

      @media(max-width:767px){
        height: $line-height-mobile;
        line-height: $line-height-mobile - 5px;
      }
    }


    input[type=checkbox] { display:none; } /* to hide the checkbox itself */

    input[type=checkbox] + label:before {
      font-family: "Font Awesome 5 Free";
      display: inline-block;
    }

    input[type=checkbox] + label:before { content: "\f0c8"; font-style: normal !important; } /* unchecked icon */
    input[type=checkbox] + label:before { letter-spacing: 10px; } /* space between checkbox and label */

    input[type=checkbox]:checked + label:before { content: "\f14a"; font-style: normal !important;
      color: lighten($btn-color,10%); } /* checked icon */
    input[type=checkbox]:checked + label:before { letter-spacing: 10px; } /* allow space for check mark */

  }

  .checkbox {
    text-align: left;
    label {
      position:relative;
      font-size:1.75em;
      pointer-events: all;
      cursor: pointer;
      left:0;

      @media(max-width:991px){
        font-size:2em;
      }
    }
  }

}

main {
  .button {
    margin-top:1rem;
  }
}

#paginationWrapper {
  display:flex;
  align-items: center;
  max-width:420px;
  margin:0 auto;
  float:none;
  text-align: center;
  justify-content: space-between;

  .pagination {
    list-style: none;
    margin:0 10px;
    padding:0;

    li {
      display: inline-block;
      margin:3px;
      padding:2px;
    }
  }
}

#copyright {
  font-size:.9em;
}

#paidfor {
  font-size:.9em;
  padding:7px 10px;
  border:.5px solid #666;
  display:inline-block;
}

.mfp-close {
  top:0;
  right:0;
}

html {
  scroll-behavior: smooth;
}

body,
html {
  background: $body-background;
}

body,
html,
input,
textarea,
select {
  color: $default;
  line-height: $base-lineheight;
  font-size: $base-fontsize;
  font-family: $body-font;
  font-weight: 300;
  @media (max-width: 1199px) {
    font-size: 16px;
  }
}

blockquote {
  font-style: italic;
  font-weight: 500;
  font-size: 1.11112rem;
  line-height: 1.54em;
  color: #333;

  border-left: solid 4px #15783c;
  padding-left: 1.1em;
  margin-bottom: 1.4em;
  padding-top: 0.4em;
  margin-top: 2.4rem;
  p {
    font-style: italic;
    font-weight: 500;
    font-size: 1.11112rem;
    line-height: 1.54em;
    color: #333333;
  }
}

p,
ul,
li {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 1.5em;
}

ul {
  padding-left: 0;
}

li {
  margin-bottom: 0;
}

a {
  outline: medium none;
  text-decoration: none;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  color: $highlight;
  &:hover {
    color: $highlight;
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
}

b,
strong {
  font-weight: 700;
}

u {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  font-weight: 900;
  line-height: 1em;
  padding-bottom: 0.45em;
  font-family: $header-font;
}

h2,
.h2 {
  font-weight: 600;
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.61112rem;
}

h4,
.h4 {
  font-weight: bold;
  font-size: 1.333rem;
}

img {
  max-width: 100%;
  height: auto;
}

#registerForm {
  .mb-3 {
    padding-bottom:7px;

    h6 {
      color: var(--primary-darker);
    }
  }
}

#profileOverview {
  p {
    margin-bottom:0;
    &:last-of-type {
      margin-bottom:20px;
    }
  }
}