/*
*
* Modal
*
* Bootstrap modal styles.
*
*/

.modal-header,
.modal-body,
.modal-footer {
  padding: var(--card-spacing);
}

.modal-content {
  border-radius: var(--border-radius-lg);
  background: var(--foreground);
}

.modal-header {
  border-top-left-radius: var(--border-radius-lg);
  border-top-right-radius: var(--border-radius-lg);
  border-color: var(--separator);
}

.modal-footer {
  border-bottom-left-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
  border-color: var(--separator);
}

.modal-under-nav .modal-dialog {
  margin-top: var(--nav-size-slim);
}

.modal-close-out {
  .btn-close {
    position: absolute;
    right: -50px;
    top: 0;
    background: var(--foreground);
    opacity: 1;
    color: var(--primary);
    border-radius: var(--border-radius-md);
    width: var(--input-height);
    height: var(--input-height);
    margin: 0;
    padding: 0;

    @include respond-below(sm) {
      right: 0;
      top: -50px;
    }
  }

  .modal-dialog:not(.modal-dialog-centered) {
    margin-top: var(--nav-size-slim);
  }
}

.modal-right {
  padding-right: 0 !important;

  .modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 330px;
  }
  .modal-body {
    height: 100% !important;
  }

  .modal-content {
    min-height: 100%;
    border: initial;
    border-radius: initial;
    border-top-left-radius: var(--border-radius-lg);
    border-bottom-left-radius: var(--border-radius-lg);
  }

  .modal-header {
    flex: 0 0 var(--nav-size-slim);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top-left-radius: var(--border-radius-lg);
  }

  .modal-footer {
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    border-bottom-left-radius: var(--border-radius-lg);
  }

  &.modal.fade .modal-dialog {
    transform: translate(25%, 0);
  }

  &.modal.show .modal-dialog {
    transform: translate(0, 0);
  }

  &.large {
    .modal-dialog {
      max-width: 530px;
    }
  }
}

.modal-left {
  padding-left: 0 !important;

  .modal-dialog {
    margin: 0 auto;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 330px;
  }
  .modal-body {
    height: 100% !important;
  }

  .modal-content {
    min-height: 100%;
    border: initial;
    border-radius: initial;
    border-top-right-radius: var(--border-radius-lg);
    border-bottom-right-radius: var(--border-radius-lg);
  }

  .modal-header {
    flex: 0 0 var(--nav-size-slim);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top-right-radius: var(--border-radius-lg);
  }

  .modal-footer {
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    border-bottom-right-radius: var(--border-radius-lg);
  }

  &.modal.fade .modal-dialog {
    transform: translate(-25%, 0);
  }

  &.modal.show .modal-dialog {
    transform: translate(0, 0);
  }

  &.large {
    .modal-dialog {
      max-width: 530px;
    }
  }
}

.modal-dialog-scrollable.long {
  .modal-body {
    overflow: initial;
    .scroll,
    .scroll-track-visible {
      max-height: calc(100vh - 25rem);
    }
  }
}

.modal-dialog-scrollable.full {
  .modal-content {
    height: 100%;
    min-height: unset;
  }
  max-height: 100% !important;
  .modal-body {
    overflow: initial;
    max-height: 100% !important;
    .scroll,
    .scroll-track-visible {
      max-height: 100%;
      height: 100%;
    }
  }
}

.modal-dialog-scrollable.short {
  .modal-body {
    overflow: initial;
    .scroll,
    .scroll-track-visible {
      max-height: calc(50vh);
    }
  }
}

.modal.modal-right {
  .os-content {
    padding: 0 !important;
  }

  .os-host-resize-disabled.os-host-scrollbar-horizontal-hidden
    > .os-scrollbar-vertical {
    padding-right: 0;
    padding-left: 4px;
  }
}

.modal.modal-left {
  .os-content {
    padding: 0 !important;
  }

  .os-host-resize-disabled.os-host-scrollbar-horizontal-hidden
    > .os-scrollbar-vertical {
    padding-right: 4px;
    padding-left: 0;
  }
}

.modal-semi-full {
  max-width: 90%;
  height: 95%;
  margin-left: auto;
  margin-right: auto;
  .modal-content {
    height: 100%;
  }
}
