/*
*
* Offcanvas
*
* Bootstrap offcanvas styles.
*
*/

.offcanvas {
  background-color: var(--foreground);
}
